import {
	Component,
	Inject,
	Input,
	LOCALE_ID,
	OnDestroy,
	OnInit
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { Bank } from "@data/schemas/bank";
import {
	PaymentMethodInstallment,
	PaymentMethodSimulate
} from "@data/schemas/payment-method";
import { SalesProposal } from "@data/schemas/sales-proposal/sales-proposal";
import { BankService } from "@data/services/bank.service";
import { CreateBusinessProposalService } from "@data/services/create-business-proposal.service";
import { PaymentMethodService } from "@data/services/payment-method.service";
import { PaymentService } from "@data/services/payment.service";
import { SalesProposalItemService } from "@data/services/sales-proposal-item.service";
import { SalesProposalService } from "@data/services/sales-proposal.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { CheckboxDetailsOptions } from "@shared/components/atlas/checkbox-details/types";
import { SelectPillOptions } from "@shared/components/atlas/select-pill/types";
import { STEP } from "@shared/components/atlas/stepper/types";
import {
	catchError,
	combineLatest,
	concat,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	of,
	Subject,
	switchMap,
	takeUntil,
	tap
} from "rxjs";

@Component({
	selector: "app-pagamento",
	templateUrl: "./pagamento.component.html",
	styleUrls: ["./pagamento.component.scss"],
	standalone: false
})
export class PagamentoComponent implements OnInit, OnDestroy {
	@Input() formStepThree!: FormGroup;

	private _onDestroy = new Subject<boolean>();

	private actualStepFromCreateBusinessProposalWizard$ =
		this.createBusinessProposalService
			.actualStepFromCreateBusinessProposalWizard$;
	private actualSalesProposal$ =
		this.salesProposalService.actualSalesProposal$;

	protected loadingTotalValueInput = false;

	protected suggestions$?: Observable<Bank[]>;

	protected selectPillOptions: SelectPillOptions[] = [
		{ label: "Valor fixo", value: 0 },
		{ label: "Percentual", value: 1 }
	];

	protected optionsCheckboxDetails: CheckboxDetailsOptions[] = [
		{
			title: "Boleto Bancário e Pix",
			subtitle: "Valor líquido R$ 0,00",
			value: 0,
			description: []
		},
		{
			title: "Cartão de Crédito",
			subtitle: "Valor líquido R$ 0,00",
			value: 1,
			description: []
		}
	];

	protected input$ = new Subject<string>();
	protected loadingResults = false;
	protected bankSearchresults$!: Observable<Bank[]>;

	protected billingTypesAsaas = "BOLETO,CREDIT_CARD,PIX";
	protected billingTypeString = "UNDEFINED";
	protected billingTypeOptions: FormArray | undefined;
	protected generateBilling$ = this.paymentService.generateBilling$;
	protected paymentMethodsNotAsaas$ = this.paymentMethodService.getAll().pipe(
		tap((paymentMethods) => {
			this.formStepThree.patchValue({
				formaPagamentoNotAsaas: paymentMethods[0].id
			});
		})
	);
	protected paymethodMethodsInstallments: PaymentMethodInstallment[] = [];
	protected paymethodMethodsInstallmentsOptionsAsaas: PaymentMethodInstallment[] =
		[];

	protected paymentMethodSimulate: PaymentMethodSimulate | undefined;
	protected statusDueDate = false;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private paymentService: PaymentService,
		private salesProposalService: SalesProposalService,
		private createBusinessProposalService: CreateBusinessProposalService,
		private bankService: BankService,
		private paymentMethodService: PaymentMethodService,
		private customSegmentService: CustomSegmentService,
		private salesProposalItemService: SalesProposalItemService
	) {}

	ngOnInit(): void {
		this.addCheckboxes();
		this.searchAutoComplete();
		this.getActualSalesProposalData();
		this.updateValuesDiscount();
		this.updateShippingDiscount();
		this.initPaymethodMethodsInstallments();
		this.checkStatusDueDateAndBankInputs();
		this.updateAsaasPaymentsAndOptions();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private checkStatusDueDateAndBankInputs(): void {
		this.paymentService.generateBilling$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((status) => {
				const dueDateControl = this.formStepThree.get(
					"dataVencimentoCobranca"
				);
				this.statusDueDate = status;
				dueDateControl?.reset();
				if (status) {
					dueDateControl?.addValidators(Validators.required);
					return;
				}
				dueDateControl?.removeValidators(Validators.required);
			});
	}

	private updateAsaasPaymentsAndOptions(): void {
		this.generateBilling$
			.pipe(
				filter((data) => data),
				takeUntil(this._onDestroy)
			)
			.subscribe(() => {
				this.updateSimulatePaymentAsaas();
				this.updateInstallmentOptionsWithTotalValueFinalAsaas();
			});
	}

	private formatCurrencyToBrl(value: number): string {
		return new Intl.NumberFormat("pt-BR", {
			minimumFractionDigits: 2
		}).format(value);
	}

	private getActualSalesProposalData(): void {
		combineLatest([
			this.actualStepFromCreateBusinessProposalWizard$,
			this.actualSalesProposal$
		])
			.pipe(
				filter(
					([actualStep, actualSalesProposal]) =>
						actualStep === STEP.THREE &&
						Boolean(actualSalesProposal)
				),
				switchMap(([_, actualSalesProposal]) =>
					this.salesProposalService.getSalesProposalById(
						actualSalesProposal?.id ?? 0
					)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe((salesProposal) => {
				this.formStepThree.patchValue({
					totalItens: salesProposal.totalProduto,
					valorTotalFinal: salesProposal.totalPropostaVenda
				});
				this.formStepThree.patchValue(
					{
						valorFrete: this.formatCurrencyToBrl(
							salesProposal.valorFrete
						),
						valorDesconto:
							+this.formStepThree.get("discountType")?.value === 0
								? this.formatCurrencyToBrl(
										salesProposal.valorDesconto
									)
								: salesProposal.percentualDesconto
					},
					{ emitEvent: false }
				);
			});
	}

	private getInputObservableToUpdate(
		inputName: string
	): Observable<number> | undefined {
		return this.formStepThree.get(inputName)?.valueChanges.pipe(
			map((value) => +value),
			filter((value) => !!value || value === 0),
			debounceTime(1000)
		);
	}

	private patchTotalValue(newValue: number): void {
		this.formStepThree.patchValue({
			valorTotalFinal: newValue
		});
	}

	private initPaymethodMethodsInstallments(): void {
		const valorTotalFinal$ = this.formStepThree
			.get("valorTotalFinal")
			?.valueChanges.pipe(
				distinctUntilChanged(),
				takeUntil(this._onDestroy)
			);

		const formaPagamentoNotAsaas$ = this.formStepThree
			.get("formaPagamentoNotAsaas")
			?.valueChanges.pipe(
				distinctUntilChanged(),
				takeUntil(this._onDestroy)
			);

		if (!valorTotalFinal$ || !formaPagamentoNotAsaas$) {
			return;
		}

		combineLatest([valorTotalFinal$, formaPagamentoNotAsaas$])
			.pipe(
				map(([valorTotalFinal, formaPagamentoNotAsaas]) => [
					+valorTotalFinal,
					+formaPagamentoNotAsaas
				]),
				switchMap(([valorTotalFinal, formaPagamentoNotAsaas]) =>
					this.paymentMethodService.getAllPaymentMethodInstallments(
						formaPagamentoNotAsaas,
						valorTotalFinal
					)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe((result) => {
				result.forEach((paymentMethodInstallment) => {
					this.paymentMethodService.actualPaymentMethodsInstallments.set(
						paymentMethodInstallment.idInstallment,
						`${paymentMethodInstallment.description}(${paymentMethodInstallment.value})`
					);
				});
				this.paymethodMethodsInstallments = result;
				this.formStepThree.patchValue({
					tipoParcelamento: result[0].idInstallment
				});
			});
	}

	private updateInstallmentOptionsWithTotalValueFinalAsaas(): void {
		const valorTotalFinal$ =
			this.getInputObservableToUpdate("valorTotalFinal");
		if (!valorTotalFinal$) {
			return;
		}

		combineLatest([valorTotalFinal$, this.actualSalesProposal$])
			.pipe(
				distinctUntilChanged(
					(prev, curr) =>
						prev[0] === curr[0] && prev[1]?.id === curr[1]?.id
				),
				switchMap(([valorTotalFinal]) =>
					this.paymentMethodService.getAllPaymentMethodInstallmentsOptionsFromAsaas(
						this.billingTypeString,
						valorTotalFinal
					)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: (result) => {
					this.paymethodMethodsInstallmentsOptionsAsaas = result;
					this.formStepThree.patchValue({
						installmentCount: +result[0].installmentCount
					});
				}
			});

		combineLatest([valorTotalFinal$, this.actualSalesProposal$])
			.pipe(
				distinctUntilChanged(
					(prev, curr) =>
						prev[0] === curr[0] && prev[1]?.id === curr[1]?.id
				),
				switchMap(([valorTotalFinal]) => {
					return this.paymentMethodService.getSimulatePaymentFromAsaas(
						this.billingTypesAsaas,
						valorTotalFinal,
						String(
							+this.formStepThree.get("installmentCount")?.value
						)
					);
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: (result) => {
					this.paymentMethodSimulate = result;
					this.updateSubtitleWithValueSimulateAsaas();
					this.updateDescriptionWithValueSimulateAsaas();
				}
			});
	}

	private updateSimulatePaymentAsaas(): void {
		const installmentCount$ = this.getInputObservableToUpdate(
			"installmentCount"
		)?.pipe(takeUntil(this._onDestroy));

		if (!installmentCount$) {
			return;
		}

		combineLatest([installmentCount$, this.generateBilling$])
			.pipe(
				filter(([_, billing]) => billing),
				distinctUntilChanged((prev, curr) => prev[0] === curr[0]),
				switchMap(() =>
					this.paymentMethodService.getSimulatePaymentFromAsaas(
						this.billingTypesAsaas,
						+this.formStepThree.get("valorTotalFinal")?.value,
						String(
							+this.formStepThree.get("installmentCount")?.value
						)
					)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: (result) => {
					this.paymentMethodSimulate = result;
					this.updateSubtitleWithValueSimulateAsaas();
					this.updateDescriptionWithValueSimulateAsaas();
				}
			});
	}

	private updateSubtitleWithValueSimulateAsaas(): void {
		if (!this.paymentMethodSimulate) {
			return;
		}

		const installmentCount =
			+this.formStepThree.get("installmentCount")?.value;

		const isInstallmentPaymentMethod =
			!!this.paymentMethodSimulate && installmentCount > 1;

		const bankSlipPixLiquidValue = isInstallmentPaymentMethod
			? this.paymentMethodSimulate.bankSlipInstallmentPaymentNetValue
			: this.paymentMethodSimulate.bankSlipNetValue;

		const creditCardLiquidValue = isInstallmentPaymentMethod
			? this.paymentMethodSimulate.creditCardPaymentNetValue
			: this.paymentMethodSimulate.creditCardNetValue;

		const titleLiquidValue = isInstallmentPaymentMethod
			? "Valor líquido por parcela: R$ "
			: "Valor líquido R$ ";

		this.optionsCheckboxDetails[0].subtitle =
			titleLiquidValue +
			String(
				Intl.NumberFormat(this.locale).format(bankSlipPixLiquidValue)
			);

		this.optionsCheckboxDetails[1].subtitle =
			titleLiquidValue +
			String(
				Intl.NumberFormat(this.locale).format(creditCardLiquidValue)
			);
	}

	private updateDescriptionWithValueSimulateAsaas(): void {
		if (!this.paymentMethodSimulate) {
			return;
		}

		this.optionsCheckboxDetails[0].description = [
			`Taxa de R$ ${Intl.NumberFormat(this.locale).format(
				this.paymentMethodSimulate.bankSlipFeeValue
			)} por Boleto recebido. Receba em 1 dia útil após o pagamento.`,
			`Taxa de R$ ${Intl.NumberFormat(this.locale).format(
				this.paymentMethodSimulate.bankSlipFeeValue
			)} por Pix recebido. Receba em alguns instantes após o pagamento.`
		];

		this.optionsCheckboxDetails[1].description = [
			`Taxa de ${Intl.NumberFormat(this.locale).format(
				this.paymentMethodSimulate.creditCardFeePercentage
			)} % sobre o valor da cobrança + R$ ${Intl.NumberFormat(
				this.locale
			).format(
				this.paymentMethodSimulate.creditCardOperationFee
			)}. Receba em 32 dias após o pagamento de cada parcela.`
		];
	}

	public getFirstParenthesesForBillingInCash(
		installment: PaymentMethodInstallment
	): string {
		if (
			installment.installmentCount === 1 &&
			Number(installment.valueFromAsaas) > 0
		) {
			return "(";
		}

		return "";
	}

	public getLastParenthesesForBillingInCash(
		installment: PaymentMethodInstallment
	): string {
		if (
			installment.installmentCount === 1 &&
			Number(installment.valueFromAsaas) > 0
		) {
			return ")";
		}

		return "";
	}

	private enableDisableFormControl({
		controlName,
		action
	}: {
		controlName: string;
		action: "enable" | "disable";
	}): void {
		if (action === "enable") {
			this.formStepThree.get(controlName)?.enable({ emitEvent: false });
			return;
		}
		this.formStepThree.get(controlName)?.disable({ emitEvent: false });
	}

	private updateValuesDiscount(): void {
		const valorDesconto$ = this.getInputObservableToUpdate(
			"valorDesconto"
		)?.pipe(takeUntil(this._onDestroy));
		if (!valorDesconto$) {
			return;
		}

		combineLatest([
			valorDesconto$,
			this.actualSalesProposal$.pipe(takeUntil(this._onDestroy))
		])
			.pipe(
				distinctUntilChanged(
					(prev, curr) =>
						prev[0] === curr[0] && prev[1]?.id === curr[1]?.id
				),
				tap(() => {
					this.enableDisableFormControl({
						controlName: "valorFrete",
						action: "disable"
					});
					this.loadingTotalValueInput = true;
				}),
				switchMap(([discountValue, actualSalesProposal]) =>
					this.salesProposalService
						.applyTotalDiscount({
							id: actualSalesProposal?.id ?? 0,
							discountValue,
							isPercentage: this.getDiscountType()
						})
						.pipe(
							tap(({ propostaVendaItens }) => {
								this.salesProposalItemService.setSalesProposalItemUpdate(
									propostaVendaItens
								);
							}),
							catchError(() => {
								this.enableDisableFormControl({
									controlName: "valorFrete",
									action: "enable"
								});
								this.loadingTotalValueInput = false;
								return of({} as Partial<SalesProposal>);
							})
						)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: ({ totalPropostaVenda, totalProduto }) => {
					if (totalPropostaVenda && totalProduto) {
						this.formStepThree.patchValue({
							totalItens: totalProduto
						});
						this.patchTotalValue(totalPropostaVenda);
						this.enableDisableFormControl({
							controlName: "valorFrete",
							action: "enable"
						});
						this.loadingTotalValueInput = false;
					}
				}
			});
	}

	private updateShippingDiscount(): void {
		const shippingDiscount$ = this.getInputObservableToUpdate(
			"valorFrete"
		)?.pipe(takeUntil(this._onDestroy));
		if (!shippingDiscount$) {
			return;
		}

		combineLatest([
			shippingDiscount$,
			this.actualSalesProposal$.pipe(takeUntil(this._onDestroy))
		])
			.pipe(
				distinctUntilChanged(
					(prev, curr) =>
						prev[0] === curr[0] && prev[1]?.id === curr[1]?.id
				),
				tap(() => {
					this.enableDisableFormControl({
						controlName: "valorDesconto",
						action: "disable"
					});
					this.loadingTotalValueInput = true;
				}),
				switchMap(([shippingValue, actualSalesProposal]) =>
					this.salesProposalService
						.applyTotalShipping({
							id: actualSalesProposal?.id ?? 0,
							shippingValue
						})
						.pipe(
							catchError(() => {
								this.enableDisableFormControl({
									controlName: "valorDesconto",
									action: "enable"
								});
								this.loadingTotalValueInput = false;
								return of({} as Partial<SalesProposal>);
							})
						)
				),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: ({ totalPropostaVenda }) => {
					if (totalPropostaVenda) {
						this.patchTotalValue(totalPropostaVenda);
						this.enableDisableFormControl({
							controlName: "valorDesconto",
							action: "enable"
						});
						this.loadingTotalValueInput = false;
					}
				}
			});
	}

	protected getDiscountType(): boolean {
		const discountType = +this.formStepThree.get("discountType")?.value;
		return !!+discountType;
	}

	private addCheckboxes(): void {
		this.optionsCheckboxDetails.forEach(() =>
			this.paymentOptions.push(new FormControl(true))
		);
	}

	private searchAutoComplete(): void {
		this.bankSearchresults$ = concat(
			of([]),
			this.input$.pipe(
				filter((inputText) => !!inputText),
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.loadingResults = true)),
				switchMap((inputText) => {
					return this.bankService.getAllBanks(true, inputText).pipe(
						catchError(() => of([])),
						tap(() => (this.loadingResults = false))
					);
				}),
				takeUntil(this._onDestroy)
			)
		);
	}

	protected selectedBank(bank: unknown): void {
		this.formStepThree.patchValue({ bancoId: (bank as Bank).id });
	}

	protected checkBillingTypeSelected(): void {
		const billingTypeOptions = this.formStepThree.get(
			"paymentOptions"
		) as FormArray;

		const billingTypeValues = billingTypeOptions.value as boolean[];

		this.billingTypeString = this.getBillingTypeOption(
			billingTypeValues[0],
			billingTypeValues[1]
		);

		this.paymentMethodService
			.getAllPaymentMethodInstallmentsOptionsFromAsaas(
				this.billingTypeString,
				+this.formStepThree.get("valorTotalFinal")?.value
			)
			.subscribe({
				next: (result) => {
					this.paymethodMethodsInstallmentsOptionsAsaas = result;
					this.formStepThree.patchValue({
						installmentCount: +result[0].installmentCount
					});
				}
			});
	}

	private getBillingTypeOption(
		isBoleto: boolean,
		isCreditCard: boolean
	): string {
		if (isBoleto && isCreditCard) {
			return "UNDEFINED";
		}

		if (isBoleto) {
			return "BOLETO";
		}

		if (isCreditCard) {
			return "CREDIT_CARD";
		}

		return "";
	}

	protected customNgSelectInputSearch(
		select: NgSelectComponent,
		event: Event
	): void {
		select.filter((<HTMLInputElement>event.target).value);
	}

	protected customSearchFn(
		term: string,
		item: PaymentMethodInstallment
	): boolean {
		const termLowerCase = term.toLowerCase();
		return (
			item.description.toLowerCase().includes(termLowerCase) ||
			item.value.toLowerCase().includes(termLowerCase)
		);
	}

	protected trackPaymentMethodInput(): void {
		this.customSegmentService.track({
			table: "base_create_wizard_sales_proposal_fullscreen",
			screen: "Step 3",
			action: "Botão qual será a forma de pagamento"
		});
	}

	get paymentOptions(): FormArray {
		return this.formStepThree.get("paymentOptions") as FormArray;
	}

	get currentDate(): Date {
		return new Date();
	}
}
