import { Component, EventEmitter, Output } from "@angular/core";
import { CustomSegmentService } from "@core/services/custom-segment.service";

@Component({
	selector: "app-documentos",
	templateUrl: "./documentos.component.html",
	styleUrls: ["./documentos.component.scss"],
	standalone: false
})
export class DocumentosComponent {
	@Output() uploadFiles = new EventEmitter<File[]>();

	protected showDragAndDropHoverEffect = false;
	protected readonly defaultMaxFileSizeMb = 5;

	constructor(private customSegmentService: CustomSegmentService) {}

	protected setShowDragAndDropHoverEffect(status: boolean): void {
		this.showDragAndDropHoverEffect = status;
	}

	protected handleUpload(files: File[]): void {
		this.customSegmentService.track({
			table: "base_create_wizard_sales_proposal_fullscreen",
			screen: "Step 4",
			action: "Botão Upload Anexo"
		});
		this.uploadFiles.emit(files);
	}
}
