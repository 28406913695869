import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { CommercialProposalStepOneForm } from "../types/form";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";

@Component({
	selector: "app-send-sales-proposal-whatsapp-modal",
	imports: [CommonModule, TranslateModule],
	templateUrl: "./send-sales-proposal-whatsapp-modal.component.html",
	styleUrl: "./send-sales-proposal-whatsapp-modal.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendSalesProposalWhatsappModalComponent {
	constructor(
		private window: Window,
		private automataTranslateService: AutomataTranslateService,
		private atlasModalService: AtlasModalService,
		private customSegmentService: CustomSegmentService
	) {}

	private readonly trackingTable =
		"base_create_wizard_sales_proposal_fullscreen";

	formData!: FormGroup<CommercialProposalStepOneForm>;
	userCompany = "";
	link = "";
	userId = "";

	private getFormattedPhoneNumber(): string {
		return `+55${this.formData.controls.telefone1.value.replace(/\D/g, "")}`;
	}

	private getWhatsappMessage(): string {
		return this.automataTranslateService.getTranslationSync(
			"SALES_PROPOSAL.SEND_WHATSAPP_MODAL.MESSAGE_WHATSAPP",
			{
				clientName: this.formData.controls.razaoSocial.value ?? "",
				companyName: this.userCompany,
				link: this.link
			}
		);
	}

	private trackSendWhatsappClick(): void {
		this.customSegmentService.track({
			table: this.trackingTable,
			screen: "baseerp_propostawhats_modal_send_whatsapp_click",
			action: "send_whatsapp_click",
			properties: {
				timestamp: new Date().toISOString(),
				user_id: this.userId
			}
		});
	}

	private openWhatsappLink(phone: string, message: string): void {
		this.window.open(
			`https://wa.me/${phone}?text=${message}`,
			"_blank",
			"noopener"
		);
	}

	private trackNewTabEvent(): void {
		if (document.hasFocus()) {
			this.customSegmentService.track({
				table: this.trackingTable,
				screen: "baseerp_propostawhats_new_tab_whatsapp_event",
				action: "new_tab_whatsapp",
				properties: {
					timestamp: new Date().toISOString(),
					user_id: this.userId
				}
			});
		}
	}

	protected sendToWhatsapp(): void {
		const phoneWhatsapp = this.getFormattedPhoneNumber();
		const message = this.getWhatsappMessage();
		this.trackSendWhatsappClick();
		this.openWhatsappLink(phoneWhatsapp, message);
		this.trackNewTabEvent();
		this.atlasModalService.closeModal();
	}

	protected closeModal(origin: "header" | "footer"): void {
		this.customSegmentService.track({
			table: this.trackingTable,
			screen:
				origin === "footer"
					? "baseerp_propostawhats_modal_cancel_click"
					: "baseerp_propostawhats_modal_close_click",
			action:
				origin === "footer"
					? "modal_cancel_click"
					: "modal_close_click",
			properties: {
				timestamp: new Date().toISOString(),
				user_id: this.userId
			}
		});
		this.atlasModalService.closeModal();
	}
}
