import { CdkStepper } from "@angular/cdk/stepper";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	UntypedFormArray,
	Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { Company } from "@data/schemas/company/company";
import { Product } from "@data/schemas/product";
import { SalesProposal } from "@data/schemas/sales-proposal/sales-proposal";
import { CompanyService } from "@data/services/company.service";
import { CreateBusinessProposalService } from "@data/services/create-business-proposal.service";
import { DocumentsService } from "@data/services/documents.service";
import { PaymentService } from "@data/services/payment.service";
import { SalesProposalService } from "@data/services/sales-proposal.service";
import { STEP } from "@shared/components/atlas/stepper/types";
import { minSelectedCheckboxesDetails } from "@shared/utils/forms/custom-validators";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map, Observable, of, Subject, switchMap, take, takeUntil } from "rxjs";
import {
	CustomSegmentService,
	TrackInfo
} from "@core/services/custom-segment.service";
import { MessageToastService } from "@shared/services/message-toast.service";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { CommercialProposalStepOneForm } from "./types/form";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { SendSalesProposalWhatsappModalComponent } from "./send-sales-proposal-whatsapp-modal/send-sales-proposal-whatsapp-modal.component";
import { AuthService } from "@core/services/auth/auth.service";
import { AbTestService } from "@data/services/ab-test.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { unmaskCpfCnpj } from "@shared/utils/string-util";

@Component({
	selector: "app-criar-proposta-comercial",
	templateUrl: "./criar-proposta-comercial.component.html",
	styleUrls: ["./criar-proposta-comercial.component.scss"],
	providers: [AlertsService],
	standalone: false
})
export class CriarPropostaComercialComponent implements OnInit, OnDestroy {
	@ViewChild("stepper")
	private stepper!: CdkStepper;
	private stepsForm: Array<FormGroup> = [];
	private _onDestroy = new Subject<boolean>();

	private readonly NAVIGATE_SALES_PROPOSAL_EDIT =
		"/comercial/proposta-comercial/edit";

	protected billingTypeAsaas = "UNDEFINED";
	protected filesToUpload: File[] = [];
	protected goToNextStepBtnLoading = false;
	protected pendingChangesStepTwo = false;
	protected showCancelLoading = false;

	protected abTest = toSignal(
		this.abTestService
			.findAbTestByName(
				"SALES_PROPOSAL_WHATSAPP",
				this.authService
					.getUserLogin()
					?.idUnidadePersistencia.toString() ?? ""
			)
			.pipe(map((result) => result.value))
	);

	protected trackInfo!: TrackInfo;
	private readonly SEGMENT_WIZARD_TABLE =
		"base_create_wizard_sales_proposal_fullscreen";

	constructor(
		private abTestService: AbTestService,
		private fb: FormBuilder,
		private createBusinessProposalService: CreateBusinessProposalService,
		private companySevice: CompanyService,
		private salesProposalService: SalesProposalService,
		private documentsService: DocumentsService,
		private paymentService: PaymentService,
		private customSegmentService: CustomSegmentService,
		protected bsModalRef: BsModalRef,
		private router: Router,
		private messageToastService: MessageToastService,
		private atlasModalService: AtlasModalService,
		private authService: AuthService,
		@Inject(DOCUMENT) private _document: Document
	) {}

	ngOnInit(): void {
		this.stepsForm = [
			this.stepOneForm,
			this.stepTwoForm,
			this.stepThreeForm
		];
		this.watchCloseModalEvent();
		this.watchPendingChangesProductStepTwo();
		this.resetViewportToStepper();

		this.trackInfo = {
			table: this.SEGMENT_WIZARD_TABLE,
			screen: "",
			action: ""
		};

		this.stepOneForm
			.get("codigoCidade")
			?.valueChanges.pipe(takeUntil(this._onDestroy))
			.subscribe((value) => {
				const codigoUfValue = value ? value.substring(0, 2) : "";
				this.stepOneForm.get("codigoUf")?.setValue(codigoUfValue);
			});
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this.resetWizard();
	}

	protected stepOneForm =
		this.fb.nonNullable.group<CommercialProposalStepOneForm>({
			accountType: this.fb.nonNullable.control<number>(
				0,
				Validators.required
			),
			id: this.fb.nonNullable.control<number>(0),
			razaoSocial: this.fb.nonNullable.control<string | null>(
				null,
				Validators.required
			),
			cnpj: this.fb.nonNullable.control<string>("", []),
			email: this.fb.nonNullable.control<string>("", [Validators.email]),
			telefone1: this.fb.nonNullable.control<string>(""),
			telefone2: this.fb.nonNullable.control<string>(""),
			nomeFantasia: this.fb.nonNullable.control<string>(""),
			cep: this.fb.nonNullable.control<string>(""),
			cidade: this.fb.control<string>({ value: "", disabled: true }),
			endereco: this.fb.nonNullable.control<string>(""),
			bairro: this.fb.nonNullable.control<string>(""),
			numero: this.fb.nonNullable.control<string>(""),
			complemento: this.fb.nonNullable.control<string>(""),
			telefone1Tipo: this.fb.nonNullable.control<{ id: string }>({
				id: "CELULAR"
			}),
			telefone2Tipo: this.fb.nonNullable.control<{ id: string }>({
				id: "COMERCIAL"
			}),
			uf: this.fb.nonNullable.control<string>(""),
			codigoCidade: this.fb.nonNullable.control<string>(""),
			pais: this.fb.nonNullable.control<string>("BRASIL"),
			codigoPais: this.fb.nonNullable.control<string>("1058"),
			codigoUf: this.fb.nonNullable.control<string>(""),
			whatsapp: this.fb.nonNullable.control<string>(""),
			sendEmail: this.fb.nonNullable.control<boolean>(false)
		});

	protected stepTwoForm = this.fb.group({
		accountType: [
			0,
			{
				validators: [Validators.required]
			}
		],
		productService: [null, { validators: [Validators.required] }],
		selectedProducts: new UntypedFormArray([]),
		companyId: [
			0,
			{
				validators: []
			}
		]
	});

	protected stepThreeForm = this.fb.group({
		discountType: [
			0,
			{
				validators: [Validators.required]
			}
		],
		totalItens: [{ value: 42, disabled: true }, [Validators.required]],
		valorFrete: [0, []],
		valorDesconto: [0, []],
		valorTotalFinal: [{ value: 0, disabled: true }, [Validators.required]],
		tipoParcelamento: ["", { validators: [Validators.required] }],
		dataVencimentoCobranca: ["", { validators: [] }],
		paymentOptions: new FormArray([], [minSelectedCheckboxesDetails()]),
		formaPagamentoNotAsaas: [0, { validators: [Validators.required] }],
		installmentCount: [1, { validators: [Validators.required] }],
		descricaoCobranca: ["", { validators: [] }],
		banco: [null, { validators: [] }],
		bancoId: [0, { validators: [] }]
	});

	protected stepTwoFormProposalValidity = this.fb.group({
		dataValidade: ["", { validators: [] }]
	});

	protected validActualStepForm(index: number): boolean {
		return this.stepsForm[index].valid;
	}

	protected validateStepOneForm(): void {
		if (this.stepOneForm) {
			Object.keys(this.stepOneForm?.controls).forEach((key) => {
				const control = this.stepOneForm?.get(key);
				control?.markAsTouched();
				control?.markAsDirty();
				control?.updateValueAndValidity();
			});
		}
	}

	protected validateStepThreeForm(): void {
		if (this.stepThreeForm) {
			Object.keys(this.stepThreeForm?.controls).forEach((key) => {
				const control = this.stepThreeForm?.get(key);
				control?.markAsTouched();
				control?.markAsDirty();
				control?.updateValueAndValidity();
				this.scrollToFirstInvalidControl();
			});
		}
	}

	private resetWizard(): void {
		this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
			STEP.ONE
		);
		this.paymentService.setGenerateBilling(false);
	}

	private getDocumentElement(selector: string): Element | null {
		return this._document.querySelector(selector);
	}

	private resetViewportToStepper(): void {
		this.createBusinessProposalService.actualStepFromCreateBusinessProposalWizard$
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() =>
				this.getDocumentElement(".steps")?.scrollIntoView({
					behavior: "smooth"
				})
			);
	}

	private watchCloseModalEvent(): void {
		this.bsModalRef.onHidden?.subscribe(() => {
			this.salesProposalService.setActualSalesProposal(undefined);
		});
	}
	private watchPendingChangesProductStepTwo(): void {
		this.selectedProductsFormArray.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe((form) => {
				const formProduct = form as {
					alreadySubmitted: boolean;
					pendingChanges: boolean;
				}[];
				this.pendingChangesStepTwo = formProduct.some(
					(product) =>
						product.alreadySubmitted === false ||
						product.pendingChanges === true
				);
			});
	}

	private fireStepOne(step: number): void {
		this.goToNextStepBtnLoading = true;

		this.salesProposalService.actualSalesProposal$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((salesProposal) => {
				if (salesProposal) {
					this.updateExistingCompany();
				} else {
					this.createOrUpdateCompanyAndSalesProposal(step);
				}
			});
	}

	private updateExistingCompany(): void {
		const company = this.stepOneForm.value as Partial<Company>;

		if (!company.id) {
			return;
		}

		this.stepTwoForm.controls.companyId.patchValue(company.id);

		const formData =
			this.stepOneForm.getRawValue() as unknown as Partial<Company>;

		if (formData.telefone1) {
			formData.telefone1 = formData.telefone1.replace(/\D/g, "");
		}

		if (formData.cnpj) {
			formData.cnpj = unmaskCpfCnpj(formData.cnpj);
		}

		this.companySevice.updateCompany(formData).subscribe(() => {
			this.goToNextStepBtnLoading = false;
			this.stepper.next();
			this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
				this.stepper.selectedIndex
			);
		});

		this.disableStepOneFormControls();
	}

	private createOrUpdateCompanyAndSalesProposal(step: number): void {
		if (
			this.stepsForm[step].get("accountType")?.value === 1 &&
			!this.stepOneForm.value.id
		) {
			this.createCompanyAndSalesProposal();
		} else {
			this.updateCompanyAndCreateSalesProposal();
		}
	}

	private createCompanyAndSalesProposal(): void {
		const formData =
			this.stepOneForm.getRawValue() as unknown as Partial<Company>;

		if (formData.telefone1) {
			formData.telefone1 = formData.telefone1.replace(/\D/g, "");
		}

		if (formData.cnpj) {
			formData.cnpj = unmaskCpfCnpj(formData.cnpj);
		}

		this.companySevice.createCompanyAndSalesProposal(formData).subscribe({
			next: ({ salesProposalId, companyId }) => {
				this.stepOneForm.patchValue({ id: companyId });
				this.stepTwoForm.controls.companyId.patchValue(companyId);
				this.salesProposalService.setActualSalesProposal({
					id: salesProposalId
				});
			},
			complete: () => {
				this.goToNextStepBtnLoading = false;
				this.stepper.next();
				this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
					this.stepper.selectedIndex
				);
			},
			error: () => {
				this.goToNextStepBtnLoading = false;
			}
		});
	}

	private updateCompanyAndCreateSalesProposal(): void {
		const formData =
			this.stepOneForm.getRawValue() as unknown as Partial<Company>;

		if (formData.telefone1) {
			formData.telefone1 = formData.telefone1.replace(/\D/g, "");
		}

		if (formData.cnpj) {
			formData.cnpj = unmaskCpfCnpj(formData.cnpj);
		}

		this.companySevice
			.updateCompanyAndCreateSalesProposal(formData)
			.subscribe({
				next: ({ salesProposalId }) => {
					this.salesProposalService.setActualSalesProposal({
						id: salesProposalId
					});
				},
				complete: () => {
					this.goToNextStepBtnLoading = false;
					this.stepper.next();
					this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
						this.stepper.selectedIndex
					);
				},
				error: () => {
					this.goToNextStepBtnLoading = false;
				}
			});
	}

	private disableStepOneFormControls(): void {
		this.stepOneForm.get("razaoSocial")?.disable();
		this.stepOneForm.get("accountType")?.disable();
		this.stepOneForm.get("cnpj")?.disable();
	}

	private updateProposalValidity(): void {
		this.goToNextStepBtnLoading = true;
		this.salesProposalService.actualSalesProposal$
			.pipe(
				switchMap((result) => {
					return this.salesProposalService.updateSalesProposal({
						id: result?.id,
						dataValidade: this.stepTwoFormProposalValidity
							.get("dataValidade")
							?.getRawValue() as string
					});
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: () => {
					this.goToNextStepBtnLoading = false;
					this.stepper.next();
					this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
						this.stepper.selectedIndex
					);
				},
				error: () => {
					this.goToNextStepBtnLoading = false;
				}
			});
	}

	private buildUpdateSalesProposalObservable(
		salesProposal: Partial<SalesProposal>
	): Observable<Partial<SalesProposal>> {
		this.goToNextStepBtnLoading = true;
		return this.salesProposalService.actualSalesProposal$.pipe(
			switchMap((actualSalesProposal) => {
				const paymentType = { ...salesProposal };
				paymentType.id = actualSalesProposal?.id;
				return this.salesProposalService.updateSalesProposal(
					paymentType
				);
			}),
			takeUntil(this._onDestroy)
		);
	}

	private buildUpdateSalesProposalActions(): {
		next: () => void;
		error: () => void;
	} {
		return {
			next: () => {
				this.goToNextStepBtnLoading = false;
				this.stepper.next();
				this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
					this.stepper.selectedIndex
				);
			},
			error: () => {
				this.goToNextStepBtnLoading = false;
			}
		};
	}

	private updateSalesProposal(): void {
		if (this.paymentService.getLastGenerateBillingValue()) {
			const updateSalesProposalUsingAsaasAsBank = {
				installmentCount: this.stepThreeForm
					.get("installmentCount")
					?.getRawValue() as number,
				billingTypeAsaasEnum: {
					id: this.getBillingTypeAsaas()
				},
				dataVencimentoCobranca: this.stepThreeForm
					.get("dataVencimentoCobranca")
					?.getRawValue() as string,
				descricaoCobranca: this.stepThreeForm
					.get("descricaoCobranca")
					?.getRawValue() as string
			} as Partial<SalesProposal>;
			this.buildUpdateSalesProposalObservable(
				updateSalesProposalUsingAsaasAsBank
			).subscribe(this.buildUpdateSalesProposalActions());
			return;
		}

		const updateSalesProposalUsingOtherBank = {
			dataVencimentoCobranca: this.stepThreeForm
				.get("dataVencimentoCobranca")
				?.getRawValue() as string,
			descricaoCobranca: String(
				this.stepThreeForm.get("descricaoCobranca")?.getRawValue()
			),
			banco: {
				id: +this.stepThreeForm.get("bancoId")?.getRawValue()
			},
			formaPagamentoPrazos: {
				id: +this.stepThreeForm.get("tipoParcelamento")?.getRawValue()
			}
		} as Partial<SalesProposal>;

		this.buildUpdateSalesProposalObservable(
			updateSalesProposalUsingOtherBank
		).subscribe(this.buildUpdateSalesProposalActions());
	}

	private getBillingTypeAsaas(): string {
		const billingTypeOptions = this.stepThreeForm.get(
			"paymentOptions"
		) as FormArray;

		const billingTypeValues = billingTypeOptions.value as boolean[];

		return this.getBillingTypeOption(
			billingTypeValues[0],
			billingTypeValues[1]
		);
	}

	private getBillingTypeOption(
		isBoleto: boolean,
		isCreditCard: boolean
	): string {
		if (isBoleto && isCreditCard) {
			return "UNDEFINED";
		}

		if (isBoleto) {
			return "BOLETO";
		}

		if (isCreditCard) {
			return "CREDIT_CARD";
		}

		return "";
	}

	private fireStepFour(): void {
		this.goToNextStepBtnLoading = true;
		this.salesProposalService.actualSalesProposal$
			.pipe(
				switchMap((actualSalesProposalId) => {
					const formData = new FormData();
					this.filesToUpload.forEach((file) =>
						formData.append("files[]", file, file.name)
					);
					formData.append("tipoChave", "PROPOSTA_COMERCIAL");
					formData.append(
						"chave",
						actualSalesProposalId?.id.toString() ?? ""
					);
					return this.documentsService.uploadMultipleFilesLegacy(
						formData
					);
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: () => {
					this.goToNextStepBtnLoading = false;
					this.stepper.next();
					this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
						this.stepper.selectedIndex
					);
				},
				error: () => {
					this.goToNextStepBtnLoading = false;
				}
			});
	}

	private fireStepMethod(step: number): void {
		if (step === STEP.ONE) {
			this.validateStepOneForm();
			if (!this.validActualStepForm(step)) {
				return;
			}
			this.fireStepOne(step);
		}
		if (step === STEP.TWO) {
			if (!this.validActualStepForm(step)) {
				return;
			}
			this.updateProposalValidity();
		}
		if (step === STEP.THREE) {
			this.validateStepThreeForm();
			if (!this.validActualStepForm(step)) {
				return;
			}
			this.updateSalesProposal();
		}
		if (step === STEP.FOUR) {
			this.fireStepFour();
		}
	}

	private segmentTrackGoToNextStep(step: number): void {
		if (step === STEP.THREE) {
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Step 3",
				action: "Botão Avançar Step 4"
			});
		}
		if (step === STEP.FOUR) {
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Step 4",
				action: "Botão Avançar Step 5"
			});
		}
	}

	private segmentTrackCloseBtnStepOne(
		closeBtnLocation: "header" | "footer" = "header"
	): void {
		if (closeBtnLocation === "header") {
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Step 1",
				action: "Botão Fechar (X)"
			});
		}
		if (closeBtnLocation === "footer") {
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Step 1",
				action: "Botão Fechar"
			});
		}
	}

	protected goToStep(step: number): void {
		this.stepper.selectedIndex = step;
	}

	protected goToPreviousStep(): void {
		if (this.stepper.selectedIndex === STEP.TWO) {
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Step 2",
				action: "Botão Voltar"
			});
		}

		this.stepper.previous();
		this.createBusinessProposalService.setActualStepFromCreateBusinessProposalWizard(
			this.stepper.selectedIndex
		);
	}

	protected goToNextStep(): void {
		const actualIndex = this.stepper.selectedIndex;
		this.segmentTrackGoToNextStep(actualIndex);
		this.fireStepMethod(actualIndex);
	}

	protected addFilesToUpload(files: File[]): void {
		this.filesToUpload = files;
	}

	addNewUnlistedProductStepTwo({
		product,
		defaultStockLocation,
		defaultMeasurementUnit
	}: {
		product: Partial<Product>;
		defaultStockLocation: number;
		defaultMeasurementUnit: number;
	}): void {
		const newProductForm = new FormGroup({
			id: new FormControl("", {
				nonNullable: true
			}),
			produtoId: new FormControl("", {
				nonNullable: true
			}),
			descricao: new FormControl(product.descricao ?? "", {
				nonNullable: true
			}),
			codigoInterno: new FormControl(product.codigoInterno ?? "", {
				nonNullable: true,
				validators: [Validators.required]
			}),
			produtoServico: new FormControl("Produto", {
				nonNullable: true
			}),
			unidEntrada: new FormControl(defaultMeasurementUnit, {
				nonNullable: true
			}),
			estoqueLocal: new FormControl(defaultStockLocation, {
				nonNullable: true
			}),
			saldoEstoqueGeral: new FormControl(product.saldoEstoque ?? 0, {
				nonNullable: true
			}),
			saldoPorEstoque: new FormControl(undefined, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			custoUnitario: new FormControl(product.custoUnitario, {
				nonNullable: true
			}),
			custoTotal: new FormControl(product.custoTotal, {
				nonNullable: true
			}),
			qtde: new FormControl(1, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			precoUnitario: new FormControl(0.0, {
				nonNullable: true
			}),
			precoTotal: new FormControl(0, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			limiteDesconto: new FormControl(0, {
				nonNullable: true,
				validators: []
			}),
			valorFrete: new FormControl(0.0, {
				nonNullable: true
			}),
			prazoEntregaData: new FormControl("", {
				nonNullable: true
			}),
			descricaoComplementar: new FormControl("", {
				nonNullable: true
			}),
			unlistedProduct: new FormControl("unlistedProduct", {
				nonNullable: true
			}),
			alreadySubmitted: new FormControl(false, {
				nonNullable: true
			}),
			pendingChanges: new FormControl(false, {
				nonNullable: true
			})
		});
		this.stepTwoForm.controls["selectedProducts"].push(newProductForm);
	}

	protected addNewProductStepTwo({
		product,
		defaultStockLocation
	}: {
		product: Product;
		defaultStockLocation: number;
	}): void {
		const newProductForm = new FormGroup({
			id: new FormControl("", {
				nonNullable: true
			}),
			produtoId: new FormControl(product.id ?? "", {
				nonNullable: true
			}),
			descricao: new FormControl(product.descricao ?? "", {
				nonNullable: true
			}),
			codigoInterno: new FormControl(product.codigoInterno ?? "", {
				nonNullable: true
			}),
			produtoServico: new FormControl("Produto", {
				nonNullable: true
			}),
			unidEntrada: new FormControl(product.unidEntrada?.unidade ?? "", {
				nonNullable: true
			}),
			estoqueLocal: new FormControl(defaultStockLocation, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			saldoEstoqueGeral: new FormControl(product.saldoEstoque ?? 0, {
				nonNullable: true
			}),
			saldoPorEstoque: new FormControl(product.saldoEstoque, {
				nonNullable: true
			}),
			custoUnitario: new FormControl(product.custoUnitario, {
				nonNullable: true
			}),
			custoTotal: new FormControl(product.custoTotal, {
				nonNullable: true
			}),
			qtde: new FormControl(1, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			precoUnitario: new FormControl(product.precoUnitario, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			precoTotal: new FormControl(0, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			limiteDesconto: new FormControl(0, {
				nonNullable: true,
				validators: []
			}),
			valorFrete: new FormControl(0.0, {
				nonNullable: true
			}),
			prazoEntregaData: new FormControl("", {
				nonNullable: true
			}),
			descricaoComplementar: new FormControl("", {
				nonNullable: true
			}),
			alreadySubmitted: new FormControl(false, {
				nonNullable: true
			}),
			pendingChanges: new FormControl(false, {
				nonNullable: true
			})
		});
		newProductForm.get("saldoEstoqueGeral")?.disable();
		newProductForm.get("saldoPorEstoque")?.disable();
		this.stepTwoForm.controls["selectedProducts"].push(newProductForm);
	}

	protected setNextBtnDisabledStatusAccordingToTheStep(): boolean {
		if (this.stepper?.selectedIndex === STEP.TWO) {
			return (
				this.selectedProductsFormArray.controls.length === 0 ||
				this.pendingChangesStepTwo
			);
		}
		return false;
	}

	protected removeProductStepTwo(i: number): void {
		(this.stepTwoForm.get("selectedProducts") as FormArray).removeAt(i);
	}

	protected undoSalesProposal(
		closeBtnLocation: "header" | "footer" = "header"
	): void {
		if (this.stepper.selectedIndex === STEP.ONE) {
			this.segmentTrackCloseBtnStepOne(closeBtnLocation);
		}
		this.showCancelLoading = true;
		this.salesProposalService.actualSalesProposal$
			.pipe(
				switchMap((actualSalesProposalId) => {
					if (!actualSalesProposalId || !actualSalesProposalId.id) {
						return of([]);
					}

					return this.salesProposalService.undoSalesProposal({
						salesProposalId: actualSalesProposalId.id
					});
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe({
				next: () => {
					this.showCancelLoading = false;
					this.bsModalRef.hide();
				},
				error: () => {
					this.showCancelLoading = false;
				}
			});
	}

	private openModalSendSalesProposalWhatsapp(): void {
		this.salesProposalService.actualSalesProposal$
			.pipe(
				take(1),
				switchMap((actualSalesProposal) => {
					const salesProposalId = actualSalesProposal?.id ?? 0;
					return this.salesProposalService.getSalesProposalPdfLink(
						salesProposalId
					);
				})
			)
			.subscribe(({ link }) => {
				const userLogin = this.authService.getUserLogin();
				const userId = userLogin?.idUsuario.toString();

				if (!userId) {
					return;
				}

				const initialState = {
					formData: this.stepOneForm,
					userCompany: userLogin?.nomeUsuario ?? "",
					link,
					userId
				};

				this.atlasModalService.openModal(
					SendSalesProposalWhatsappModalComponent,
					{ initialState }
				);
			});
	}

	protected finishSalesProposal(): void {
		this.customSegmentService.track({
			table: "base_create_wizard_sales_proposal_fullscreen",
			screen: "Step 5",
			action: "Botão Finalizar Proposta"
		});
		this.salesProposalService.actualSalesProposal$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((actualSalesProposalId) => {
				this.messageToastService.successDefaultToast({
					title: `Proposta criada com sucesso!`,
					options: {
						toastClass: "toast-top-resume ngx-toastr"
					}
				});
				this.bsModalRef.hide();
				if (
					this.abTest() === "B" &&
					!!this.stepOneForm.controls.telefone1.value
				) {
					this.openModalSendSalesProposalWhatsapp();
				}
				void this.router.navigate([
					this.NAVIGATE_SALES_PROPOSAL_EDIT,
					actualSalesProposalId?.id
				]);
			});
	}

	private scrollToFirstInvalidControl(): void {
		this.getDocumentElement("form .ng-invalid")?.scrollIntoView({
			behavior: "smooth"
		});
	}

	get selectedProductsFormArray(): FormArray {
		return this.stepTwoForm?.get("selectedProducts") as FormArray;
	}
}
