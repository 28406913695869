import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { PaymentMethodService } from "@data/services/payment-method.service";
import { PaymentService } from "@data/services/payment.service";
import { SalesProposalService } from "@data/services/sales-proposal.service";
import { CreateBusinessProposalService } from "@data/services/create-business-proposal.service";
import {
	combineLatest,
	filter,
	Observable,
	Subject,
	switchMap,
	takeUntil
} from "rxjs";
import { STEP } from "@shared/components/atlas/stepper/types";
import { SalesProposal } from "@data/schemas/sales-proposal/sales-proposal";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { AlertsService } from "@shared/components/atlas/alerts/services";

@Component({
	selector: "app-resumo",
	templateUrl: "./resumo.component.html",
	styleUrls: ["./resumo.component.scss"],
	standalone: false
})
export class ResumoComponent implements OnInit, OnDestroy {
	@Input() formStepOne: FormGroup | null = null;
	@Input() formStepTwo: FormGroup | null = null;
	@Input() formStepThree: FormGroup | null = null;
	@Input() formStepTwoProposalValidity: FormGroup | null = null;
	@Input() filesToUpload: File[] = [];
	@Output() goToStep = new EventEmitter<number>();

	private salesProposalService = inject(SalesProposalService);
	private alertsService = inject(AlertsService);
	private _onDestroy = new Subject<boolean>();

	protected generateBilling$ = this.paymentService.generateBilling$;
	protected updatePaymentInfo$ = this.updatePaymentInfo();
	protected salesProposal!: SalesProposal;
	isLoadingDownloadBtn = false;
	isLoadingSendEmailBtn = false;

	constructor(
		private paymentMethodService: PaymentMethodService,
		private paymentService: PaymentService,
		private createBusinessProposalService: CreateBusinessProposalService,
		private customSegmentService: CustomSegmentService
	) {}

	ngOnInit(): void {
		this.updatePaymentInfo();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private updatePaymentInfo(): Observable<SalesProposal> {
		const actualStepFromCreateBusinessProposalWizard$ =
			this.createBusinessProposalService
				.actualStepFromCreateBusinessProposalWizard$;
		const actualSalesProposal$ =
			this.salesProposalService.actualSalesProposal$;

		return combineLatest([
			actualStepFromCreateBusinessProposalWizard$,
			actualSalesProposal$
		]).pipe(
			filter(([actualStep, _]) => actualStep === STEP.FIVE),
			switchMap(([_, salesProposal]) =>
				this.salesProposalService.getSalesProposalById(
					salesProposal?.id ?? 0
				)
			),
			takeUntil(this._onDestroy)
		);
	}

	protected getFormControlValueStepOne(name: string): string {
		return String(this.formStepOne?.get(name)?.getRawValue());
	}

	protected getFormControlValueSteTwo(name: string): string {
		return String(this.formStepTwo?.get(name)?.getRawValue());
	}

	protected getFormControlValueStepThree(name: string): string {
		const control = this.formStepThree?.get(name)?.getRawValue() as
			| string
			| { value: string };
		if (typeof control === "object" && control !== null) {
			return control.value;
		}
		return String(control);
	}

	protected handleGoToStep(step: number): void {
		this.goToStep.emit(step);
	}

	protected get selectedProductsFormArray(): FormArray {
		return this.formStepTwo?.get("selectedProducts") as FormArray;
	}

	protected get showProductsTotalPrice(): number {
		return this.selectedProductsFormArray.controls.reduce(
			(previous, actual) =>
				previous + +actual.get("precoTotal")?.getRawValue(),
			0
		);
	}

	protected downloadSalesProposalPDF(): void {
		this.customSegmentService.track({
			table: "base_create_wizard_sales_proposal_fullscreen",
			screen: "Step 5",
			action: "Baixar proposta"
		});
		this.isLoadingDownloadBtn = true;
		this.salesProposalService.actualSalesProposal$
			.pipe(
				switchMap((salesProposal) =>
					combineLatest([
						this.salesProposalService.actualSalesProposal$,
						this.salesProposalService.downloadSalesProposalPDF({
							salesProposalId: salesProposal?.id ?? 0
						})
					])
				),
				takeUntil(this._onDestroy)
			)
			.subscribe(([salesProposal, response]): void => {
				this.requestSalesProposalNumberAndForceDownload(
					salesProposal?.id ?? 0,
					response as Blob
				);

				this.isLoadingDownloadBtn = false;
				this.alertsService.addAlerts({
					title: "Sucesso!",
					message: "PDF gerado com sucesso!",
					severity: "success"
				});
			});
	}

	protected sendEmailSalesProposal(): void {
		this.isLoadingSendEmailBtn = true;
		this.salesProposalService.actualSalesProposal$
			.pipe(
				switchMap((salesProposal) =>
					this.salesProposalService.sendEmailSalesProposal({
						salesProposalId: salesProposal?.id ?? 0
					})
				)
			)
			.subscribe(() => {
				this.isLoadingSendEmailBtn = false;
				this.alertsService.addAlerts({
					title: "Sucesso!",
					message: "Email enviado com sucesso!",
					severity: "success"
				});
			});
	}

	protected getActualPaymentMethodsInstallments(
		type: string | number
	): string | undefined {
		return this.paymentMethodService.actualPaymentMethodsInstallments.get(
			+type
		);
	}

	protected getActualPaymentMethods(
		type: string | number
	): string | undefined {
		return this.paymentMethodService.actualPaymentMethods.get(+type);
	}

	protected getSalesProposalValidity(): string {
		return String(
			this.formStepTwoProposalValidity?.get("dataValidade")?.getRawValue()
		);
	}

	protected requestSalesProposalNumberAndForceDownload(
		salesProposalId: number,
		blob: Blob
	): void {
		this.salesProposalService
			.getSalesProposalById(salesProposalId)
			.subscribe((salesProposal: SalesProposal) => {
				const fileURL = URL.createObjectURL(blob);
				const element = document.createElement("a");
				const companyName = salesProposal.empresa.razaoSocial ?? "";
				element.href = fileURL;
				element.target = "_blank";
				element.rel = "noopener noreferrer";
				element.download = `PROPOSTA_${
					salesProposal.nrPropostaVenda
				}_${companyName.split(" ").join("_")}.pdf`;
				element.click();
				element.remove();
			});
	}
}
