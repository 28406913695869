import { Component, Input } from "@angular/core";
import { PopoverPositions } from "./types";

@Component({
	selector: "app-header-pagamento",
	templateUrl: "./header-pagamento.component.html",
	styleUrls: ["./header-pagamento.component.scss"],
	standalone: false
})
export class HeaderPagamentoComponent {
	@Input() sectionTitle = "";
	@Input() showPopover = false;
	@Input() popoverTitle = "";
	@Input() popoverDescription = "";
	@Input() popoverPosition: PopoverPositions = "bottom";
}
